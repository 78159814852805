import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import {
  LocalStoreKeyConfig,
  MutationsConfigs,
  ActionsConfigs,
} from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
const getConfigs = (KeyConfig, urlApi, Mutation, vuex) => {
  if (localStorage.getItem(KeyConfig) === null) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(urlApi)
        .then(({ data }) => {
          //console.log(Mutation);
          vuex.context.commit(Mutation, data.data);
          localStorage.setItem(KeyConfig, JSON.stringify(data.data));
          resolve();
        })
        .catch(({ response }) => {
          vuex.context.commit(
            MutationsConfigs.SET_ERROR_CONFIG_BOOKING,
            response.data.message
          );
          reject();
        });
    });
  } else {
    vuex.context.commit(
      Mutation,
      JSON.parse(localStorage.getItem(KeyConfig) || "{}")
    );
  }
};
@Module
export default class ServiceModule extends VuexModule {
  product_categories = {};
  unitPrice = {};
  direction = {};
  statusQuo = {};
  acreage = {};
  roadWidth = {};
  floor = {};
  room = {};
  price_type = {};
  product_type = {};
  account_type = {};
  post_type = {};
  provinces = [];
  furniture = [];
  errors = {};

  get getUnitPrice() {
    return this.unitPrice;
  }
  get getProductCategories() {
    return this.product_categories;
  }
  get getProvinces() {
    return this.provinces;
  }
  get getDirection() {
    return this.direction;
  }
  get getFurniture() {
    return this.furniture;
  }
  get getStatusQuo() {
    return this.statusQuo;
  }
  get getAcreage() {
    return this.acreage;
  }
  get getRoadWidth() {
    return this.roadWidth;
  }
  get getFloor() {
    return this.floor;
  }
  get getRoom() {
    return this.room;
  }
  get getPriceType() {
    return this.price_type;
  }
  get getProductType() {
    return this.product_type;
  }
  get getAccountType() {
    return this.account_type;
  }
  get getPostType() {
    return this.post_type;
  }
  @Mutation
  [MutationsConfigs.SET_ACREAGE](payload): void {
    this.acreage = payload;
  }
  @Mutation
  [MutationsConfigs.SET_PRODUCT_CATEGORIES](payload): void {
    this.product_categories = payload;
  }
  @Mutation
  [MutationsConfigs.SET_FURNITURE_CONFIGS](payload): void {
    this.furniture = payload;
  }
  @Mutation
  [MutationsConfigs.SET_PROVINCES](payload): void {
    this.provinces = payload;
  }
  @Mutation
  [MutationsConfigs.SET_ERROR_CONFIG_BOOKING](payload): void {
    this.errors = payload;
  }
  @Mutation
  [MutationsConfigs.SET_DIRECTION](payload): void {
    this.direction = payload;
  }
  @Mutation
  [MutationsConfigs.SET_FLOOR](payload): void {
    this.floor = payload;
  }
  @Mutation
  [MutationsConfigs.SET_PRICE_TYPE](payload): void {
    this.price_type = payload;
  }
  @Mutation
  [MutationsConfigs.SET_ROOM](payload): void {
    this.room = payload;
  }
  @Mutation
  [MutationsConfigs.SET_ROAD_WIDTH](payload): void {
    this.roadWidth = payload;
  }
  @Mutation
  [MutationsConfigs.SET_STATUS_QUO](payload): void {
    this.statusQuo = payload;
  }
  @Mutation
  [MutationsConfigs.SET_PRODUCT_TYPE](payload): void {
    this.product_type = payload;
  }
  @Mutation
  [MutationsConfigs.SET_UNIT_PRICE](payload): void {
    this.unitPrice = payload;
  }
  @Mutation
  [MutationsConfigs.SET_ACCOUNT_TYPE](payload): void {
    this.account_type = payload;
  }
  @Mutation
  [MutationsConfigs.SET_POST_TYPE](payload): void {
    this.post_type = payload;
  }
  @Action
  async [ActionsConfigs.GET_ACCOUNT_TYPE]() {
    await getConfigs(
      LocalStoreKeyConfig.ACCOUNT_TYPE,
      "booking-config/type-account",
      MutationsConfigs.SET_ACCOUNT_TYPE,
      this
    );
  }
  @Action
  async [ActionsConfigs.GET_POST_TYPE]() {
    await getConfigs(
      LocalStoreKeyConfig.POST_TYPE,
      "booking-config/type-post",
      MutationsConfigs.SET_POST_TYPE,
      this
    );
  }
  @Action
  async [ActionsConfigs.GET_PRODUCT_CATEGORIES]() {
    await getConfigs(
      LocalStoreKeyConfig.PRODUCT_CATEGORIES,
      'category?page=1&limit=100&order=desc&search={"type":"product"}&order_by=name',
      MutationsConfigs.SET_PRODUCT_CATEGORIES,
      this
    );
  }
  @Action
  [ActionsConfigs.GET_UNIT_PRICE]() {
    getConfigs(
      LocalStoreKeyConfig.UNIT_PRICE,
      "booking-config/unit-price",
      MutationsConfigs.SET_UNIT_PRICE,
      this
    );
  }
  @Action
  [ActionsConfigs.GET_FURNITURE_CONFIGS]() {
    getConfigs(
      LocalStoreKeyConfig.FURNITURE_CONFIGS,
      "booking-config/furniture",
      MutationsConfigs.SET_FURNITURE_CONFIGS,
      this
    );
  }
  @Action
  async [ActionsConfigs.GET_PROVINCES]() {
    // await getConfigs(LocalStoreKeyConfig.PROVINCES,'province',MutationsConfigs.SET_PROVINCES,this);

    if (localStorage.getItem(LocalStoreKeyConfig.PROVINCES) === null) {
      return new Promise<void>((resolve, reject) => {
        ApiService.setHeader();
        ApiService.setHeaderNonCache("");
        ApiService.get("province")
          .then(({ data }) => {
            //console.log(Mutation);
            this.context.commit(MutationsConfigs.SET_PROVINCES, data.data);
            localStorage.setItem(
              LocalStoreKeyConfig.PROVINCES,
              JSON.stringify(data.data)
            );
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(
              MutationsConfigs.SET_ERROR_CONFIG_BOOKING,
              response.data.message
            );
            reject();
          });
      });
    } else {
      this.context.commit(
        MutationsConfigs.SET_PROVINCES,
        JSON.parse(localStorage.getItem(LocalStoreKeyConfig.PROVINCES) || "{}")
      );
    }
  }
  @Action
  [ActionsConfigs.GET_PRODUCT_TYPE]() {
    getConfigs(
      LocalStoreKeyConfig.PRODUCT_TYPE,
      "booking-config/type",
      MutationsConfigs.SET_PRODUCT_TYPE,
      this
    );
  }
  @Action
  [ActionsConfigs.GET_PRICE_TYPE]() {
    getConfigs(
      LocalStoreKeyConfig.PRICE_TYPE,
      "booking-config/price",
      MutationsConfigs.SET_PRICE_TYPE,
      this
    );
  }
  @Action
  [ActionsConfigs.GET_ACREAGE]() {
    getConfigs(
      LocalStoreKeyConfig.ACREAGE,
      "booking-config/acreage",
      MutationsConfigs.SET_ACREAGE,
      this
    );
  }
  @Action
  [ActionsConfigs.GET_ROAD_WIDTH]() {
    getConfigs(
      LocalStoreKeyConfig.ROAD_WIDTH,
      "booking-config/road-width",
      MutationsConfigs.SET_ROAD_WIDTH,
      this
    );
  }
  @Action
  [ActionsConfigs.GET_FLOOR]() {
    getConfigs(
      LocalStoreKeyConfig.FLOOR,
      "booking-config/floor",
      MutationsConfigs.SET_FLOOR,
      this
    );
  }

  @Action
  [ActionsConfigs.GET_DIRECTION]() {
    getConfigs(
      LocalStoreKeyConfig.DIRECTION,
      "booking-config/direction",
      MutationsConfigs.SET_DIRECTION,
      this
    );
  }
  @Action
  [ActionsConfigs.GET_ROOM]() {
    getConfigs(
      LocalStoreKeyConfig.ROOM,
      "booking-config/room",
      MutationsConfigs.SET_ROOM,
      this
    );
  }
  @Action
  [ActionsConfigs.GET_STATUS_QUO]() {
    getConfigs(
      LocalStoreKeyConfig.STATUS_QUO,
      "booking-config/status-quo",
      MutationsConfigs.SET_STATUS_QUO,
      this
    );
  }
}
