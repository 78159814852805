import ApiService from "@/core/services/ApiService";
import {  ActionsConfigs, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
export interface BrokerRequestUpgradeModel {
  _id?: string;
  email: string;
  phone: string;
  name: string;
  status: string;
  note: string;
}
export const BrokerRequestUpgradeDefault: BrokerRequestUpgradeModel = {
  email: "",
  phone: "",
  name: "",
  status: "pending",
  note: "",

};

export interface BrokerRequestUpgradeData {
  errors: Array<string>;
  broker_request_upgrade: BrokerRequestUpgradeModel;
  listData: Array<BrokerRequestUpgradeModel>;
  totalPages: number;
  totalPending: number;
  totalDocs: number;
}
@Module
export default class BrokerRequestUpgradeModule
  extends VuexModule
  implements BrokerRequestUpgradeData
{
  errors = [];
  broker_request_upgrade = {} as BrokerRequestUpgradeModel;
  listData = [];
  totalPages = 0;
  totalDocs = 0;
  totalPending = 0;
  get getTotalBrokerRequestUpgrades(): number {
    return this.totalDocs;
  }
  get getTotalPendingBrokerRequestUpgrades(): number {
    return this.totalPending;
  }
  get getBrokerRequestUpgradePages(): number {
    return this.totalPages;
  }
  get getBrokerRequestUpgradeErrors(): Array<string> {
    return this.errors;
  }
  get listDataBrokerRequestUpgrades(): Array<BrokerRequestUpgradeModel> {
    return this.listData;
  }
  get currentBrokerRequestUpgrade(): BrokerRequestUpgradeModel {
    return this.broker_request_upgrade;
  }
  @Mutation
  [Mutations.SET_ERROR_BROKER_REQUEST_UPGRADE](error) {
    this.errors = error;
  }
  @Mutation
  [Mutations.SET_TOTAL_PENDING_BROKER_REQ_UPGRADE](total) {
    this.totalPending = total;
  }
  @Mutation
  [Mutations.SET_CURRENT_BROKER_REQUEST_UPGRADE](data) {
    this.broker_request_upgrade = data;
  }
  @Mutation
  [Mutations.SET_ALL_BROKER_REQUEST_UPGRADE_DATA](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }
  @Action
  [ActionsConfigs.GET_ALL_BROKER_REQUEST_UPGRADE](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get("broker-request-upgrade", params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_ALL_BROKER_REQUEST_UPGRADE_DATA,
            data
          );
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_BROKER_REQUEST_UPGRADE,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [ActionsConfigs.GET_TOTAL_PENDING_BROKER_REQUEST_UPGRADE](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get("broker-request-upgrade", params)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_TOTAL_PENDING_BROKER_REQ_UPGRADE,
            data.totalDocs
          );
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_BROKER_REQUEST_UPGRADE,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [ActionsConfigs.GET_BROKER_REQUEST_UPGRADE_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get("broker-request-upgrade/show", id)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_VALUATION", data.data);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit("SET_VALUATION_ERROR", response.data.message);
          reject();
        });
    });
  }

  @Action
  [ActionsConfigs.UPDATE_BROKER_REQUEST_UPGRADE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("broker-request-upgrade/update-status", payload)
        .then(({ data }) => {
          // this.context.commit("SET_CURRENT_VALUATION", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit("SET_VALUATION_ERROR", response.data.message);
          reject();
        });
    });
  }

  @Action
  [ActionsConfigs.DELETE_BROKER_REQUEST_UPGRADE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(
        "broker-request-upgrade/delete/" + payload.id + "?force=true"
      )
        .then(() => {
          this.context.dispatch(
            ActionsConfigs.GET_ALL_BROKER_REQUEST_UPGRADE,
            payload.searchString
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
