import ApiService from "@/core/services/ApiService";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Project, ProjectDefault } from "@/store/model/Project";
import { Province, ProvinceDefault } from "@/store/model/Province";
import { AdminDefault } from "@/store/model";

export interface image {
  name: string;
  path: string;
}

export interface ProjectData {
  errors: Array<string>;
  project: Project;
  listDataProvince: Array<Province>;
  listData: Array<Project>;
  totalPages: number;
  totalDocs: number;
}

const model_name = "project";
@Module
export default class ProjectModule extends VuexModule implements ProjectData {
  errors = [];
  project = {} as Project;
  listDataProvince = [];
  listData = [];
  totalPages = 0;
  totalDocs = 0;

  get getTotalProject(): number {
    return this.totalDocs;
  }

  get getProjectPages(): number {
    return this.totalPages;
  }

  get getProjectErrors(): Array<string> {
    return this.errors;
  }

  get listDataProject(): Array<Project> {
    return this.listData;
  }

  get getListDataProvince(): Array<Province> {
    return this.listDataProvince;
  }

  get currentProject(): Project {
    return this.project;
  }

  @Mutation
  [Mutations.SET_ERROR_PROJECT](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CURRENT_PROJECT](data) {
    this.project = data;
  }

  @Mutation
  [Mutations.SET_PROJECTS_DATA](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }

  @Mutation
  [Mutations.SET_PROVINCE_DATA](data) {
    this.listDataProvince = data.data;
  }

  @Action
  [Actions.GET_ALL_PROJECT](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get(model_name + "/index", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROJECTS_DATA, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_ALL_PROVINCE](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("province", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_PROVINCE_DATA, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PROJECT_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get(model_name + "/show", id)
        .then(({ data }) => {
          const data_ = { ...ProjectDefault, ...data.data };
          this.context.commit(Mutations.SET_CURRENT_PROJECT, data_);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_PROJECT](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.post(model_name + "/create", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_PROJECT, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PROJECT](payload) {
    ApiService.setHeader();
    ApiService.setAdminPermission();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(model_name + "/update", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_PROJECT, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PROJECT](payload) {
    ApiService.setHeader();
    ApiService.setAdminPermission();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(model_name + "/delete/" + payload.id + "?force=true")
        .then(() => {
          this.context.dispatch(Actions.GET_ALL_PROJECT, payload.searchString);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
