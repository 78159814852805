import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

const prefix = "ROLE_";
export interface Permission {
  permission_id: string;
  show: boolean;
  edit: boolean;
  delete: boolean;
  create: boolean;
}
export interface Role {
  name: string;
  permissions: Array<Permission>;
  id: string;
  _id: string;
}

export interface RoleInfo {
  errors: Array<string>;
  role: Role;
  listData: Array<Role>;
  totalPages: number;
  totalDocs: number;
}

@Module
export default class RoleModule extends VuexModule implements RoleInfo {
  errors = [];
  role = {} as Role;
  listData = [];
  totalPages = 0;
  totalDocs = 0;

  get getTotalRoles(): number {
    return this.totalDocs;
  }
  get getRolePages(): number {
    return this.totalPages;
  }
  get getRoleErrors(): Array<string> {
    return this.errors;
  }

  get listDataRoles(): Array<Role> {
    return this.listData;
  }

  get currentRole(): Role {
    this.role.id = this.role._id;
    return this.role;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  ["SET_CURRENT_ROLE"](data) {
    this.role = data;
  }

  @Mutation
  ["M_GET_ROLES_DATA"](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }

  @Action
  ["GET_ROLES_DATA"](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("role", params)
        .then(({ data }) => {
          this.context.commit("M_GET_ROLES_DATA", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  ["GET_ROLE_DETAIL"](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("role/show", id)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_ROLE", data.data);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  ["ACTION_ADD_ROLE"](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("role/create", credentials)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_ROLE", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  ["UPDATE_ROLE"](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("role/update", payload)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_ROLE", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  ["DELETE_ROLE"](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("role/delete/" + payload.id)
        .then(() => {
          this.context.dispatch("GET_ROLES_DATA", payload.searchString);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
