import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { categoryModel } from "@/store/model/Category";
const routerBaseAPI = "category";

export interface CategoryData {
  errors: Array<string>;
  listMotherCategory: Array<categoryModel>;
  listCategory: Array<categoryModel>;
  currentCategory: categoryModel;
  totalPages: number;
  totalDocs: number;
}
@Module
export default class CategoryModule extends VuexModule implements CategoryData {
  errors = [];
  listMotherCategory = [];
  listCategory = [];
  currentCategory = {} as categoryModel;
  totalPages = 0;
  totalDocs = 0;
  get getTotalCategory(): number {
    return this.totalDocs;
  }
  get getTotalPagesCategory(): number {
    return this.totalPages;
  }
  get getErrorsCategory(): Array<string> {
    return this.errors;
  }
  get getCurrentCategory(): categoryModel {
    return this.currentCategory;
  }
  get listDataCategory(): Array<categoryModel> {
    return this.listCategory;
  }
  get getListMotherCategory(): Array<categoryModel> {
    return this.listMotherCategory;
  }
  @Mutation
  [Mutations.SET_ERROR_NEWS_CATEGORY](error) {
    this.errors = error;
  }
  @Mutation
  [Mutations.SET_PARENT_CATEGORY](data) {
    this.listMotherCategory = data;
  }
  @Mutation
  [Mutations.SET_ALL_NEWS_CATEGORIES_DATA](data) {
    this.listCategory = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }
  @Mutation
  [Mutations.SET_CURRENT_NEWS_CATEGORY](data) {
    this.currentCategory = data;
  }
  @Action
  [Actions.GET_ALL_NEWS_CATEGORY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get(routerBaseAPI, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_NEWS_CATEGORIES_DATA, data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_NEWS_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.ADD_NEWS_CATEGORY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();

      ApiService.post(routerBaseAPI + "/create", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_NEWS_CATEGORY, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_NEWS_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_NEWS_CATEGORY_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();

      ApiService.get(routerBaseAPI + "/show", id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_NEWS_CATEGORY, data.data);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_NEWS_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.UPDATE_NEWS_CATEGORY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();

      ApiService.put(routerBaseAPI + "/update", params)
        .then(({ data }) => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_NEWS_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.DELETE_NEWS_CATEGORY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.delete(routerBaseAPI + "/delete/" + payload.id)
        .then(() => {
          this.context.dispatch(
            Actions.GET_ALL_NEWS_CATEGORY,
            payload.searchString
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_NEWS_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
}
