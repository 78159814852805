import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ProjectCategoryModel } from "@/store/model/ProjectCategory";
const routerBaseAPI = "category";

export interface ProjectCategoryData {
  errors: Array<string>;
  listMotherProjectCategory: Array<ProjectCategoryModel>;
  listProjectCategory: Array<ProjectCategoryModel>;
  currentProjectCategory: ProjectCategoryModel;
  totalPages: number;
  totalDocs: number;
}
@Module
export default class ProjectCategoryModule
  extends VuexModule
  implements ProjectCategoryData
{
  errors = [];
  listMotherProjectCategory = [];
  listProjectCategory = [];
  currentProjectCategory = {} as ProjectCategoryModel;
  totalPages = 0;
  totalDocs = 0;
  get getTotalProjectCategory(): number {
    return this.totalDocs;
  }
  get getTotalPagesProjectCategory(): number {
    return this.totalPages;
  }
  get getErrorsProjectCategory(): Array<string> {
    return this.errors;
  }
  get getCurrentProjectCategory(): ProjectCategoryModel {
    return this.currentProjectCategory;
  }
  get listDataProjectCategory(): Array<ProjectCategoryModel> {
    return this.listProjectCategory;
  }
  get getListMotherProjectCategory(): Array<ProjectCategoryModel> {
    return this.listMotherProjectCategory;
  }
  @Mutation
  [Mutations.SET_ERROR_PROJECT_CATEGORY](error) {
    this.errors = error;
  }
  @Mutation
  [Mutations.SET_PARENT_CATEGORY](data) {
    this.listMotherProjectCategory = data;
  }
  @Mutation
  [Mutations.SET_ALL_PROJECT_CATEGORIES_DATA](data) {
    this.listProjectCategory = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }
  @Mutation
  [Mutations.SET_CURRENT_PROJECT_CATEGORY](data) {
    this.currentProjectCategory = data;
  }
  @Action
  [Actions.GET_ALL_PROJECT_CATEGORY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get(routerBaseAPI, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_PROJECT_CATEGORIES_DATA, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PROJECT_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.ADD_PROJECT_CATEGORY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();

      ApiService.post(routerBaseAPI + "/create", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_PROJECT_CATEGORY, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PROJECT_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_PROJECT_CATEGORY_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();

      ApiService.get(routerBaseAPI + "/show", id)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_CURRENT_PROJECT_CATEGORY,
            data.data
          );
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PROJECT_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.UPDATE_PROJECT_CATEGORY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();

      ApiService.put(routerBaseAPI + "/update", params)
        .then(({ data }) => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PROJECT_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.DELETE_PROJECT_CATEGORY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.delete(routerBaseAPI + "/delete/" + payload.id)
        .then(() => {
          this.context.dispatch(
            Actions.GET_ALL_PROJECT_CATEGORY,
            payload.searchString
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PROJECT_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
}
