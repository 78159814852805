import ApiService from "@/core/services/ApiService";
import { Actions, ActionsConfigs, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { CurriculumVitaeModel } from "@/store/model/CuriculumVitae";

export interface CurriculumVitaeData {
  errors: Array<string>;
  curriculum_vitae: CurriculumVitaeModel;
  listData: Array<CurriculumVitaeModel>;
  totalPages: number;
  totalDocs: number;
  totalPending: number;
}
@Module
export default class CurriculumVitaeModule extends VuexModule implements CurriculumVitaeData {
  errors = [];
  curriculum_vitae = {} as CurriculumVitaeModel;
  listData = [];
  totalPages = 0;
  totalDocs = 0;
  totalPending = 0;

  get getTotalCurriculumVitaes(): number {
    return this.totalDocs;
  }
  get getTotalPendingCurriculumVitaes(): number {
    return this.totalPending;
  }
  get getCurriculumVitaePages(): number {
    return this.totalPages;
  }
  get getCurriculumVitaeErrors(): Array<string> {
    return this.errors;
  }
  get listDataCurriculumVitaes(): Array<CurriculumVitaeModel> {
    return this.listData;
  }
  get currentCurriculumVitae(): CurriculumVitaeModel {
    return this.curriculum_vitae;
  }
  @Mutation
  [Mutations.SET_ERROR_CURRICULUM_VITAE](error) {
    this.errors = error;
  }
  @Mutation
  [Mutations.SET_TOTAL_PENDING_CURRICULUM_VITAE](totalPending) {
    this.totalPending = totalPending;
  }
  @Mutation
  [Mutations.SET_CURRENT_CURRICULUM_VITAE](data) {
    this.curriculum_vitae = data;
  }
  @Mutation
  [Mutations.SET_ALL_CURRICULUM_VITAE_DATA](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }
  @Action
  [ActionsConfigs.GET_ALL_CURRICULUM_VITAE](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get("curriculum-vitae", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_CURRICULUM_VITAE_DATA, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_CURRICULUM_VITAE,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [ActionsConfigs.GET_TOTAL_PENDING_CURRICULUM_VITAE](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get("curriculum-vitae", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_PENDING_CURRICULUM_VITAE, data.totalDocs);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_CURRICULUM_VITAE,
            response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [ActionsConfigs.GET_CURRICULUM_VITAE_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get("curriculum-vitae/show", id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_CURRICULUM_VITAE, data.data);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_CURRICULUM_VITAE,
            response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [ActionsConfigs.UPDATE_CURRICULUM_VITAE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("curriculum-vitae/update-status", payload)
        .then(({ data }) => {
          // this.context.commit("SET_CURRENT_CURRICULUM_VITAE", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR_CURRICULUM_VITAE, response.data.message);
          reject();
        });
    });
  }

  @Action
  [ActionsConfigs.DELETE_CURRICULUM_VITAE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("curriculum-vitae/delete/" + payload.id)
        .then(() => {
          this.context.dispatch(ActionsConfigs.GET_ALL_CURRICULUM_VITAE, payload.searchString);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit("SET_CURRICULUM_VITAE_ERROR", response.data.errors);
          reject();
        });
    });
  }
}
