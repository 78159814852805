import { SeoModel } from "@/store/model";

export interface ProductCategoryModel {
  _id?: string;
  name: string;
  parent: string | null;
  status: string;
  slug: string;
  type: string;
  seo: SeoModel;
  children:
    | {
        _id: string;
        name: string;
        slug: string;
        title: string;
      }[]
    | null;
}

export const productCategoryDefault: ProductCategoryModel = {
  seo: {
    robot: "",
    title: "",
    keywords: "",
    description: "",
  },
  _id: "",
  name: "",
  parent: "",
  status: "active",
  slug: "",
  type: "product",
  children: [],
};
