import ApiService from "@/core/services/ApiService";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { PostSearch, PostSearchDefault } from "@/store/model/PostSearch";

export interface image {
  name: string;
  path: string;
}

export interface PostSearchData {
  errors: Array<string>;
  post_search: PostSearch;
  listData: Array<PostSearch>;
  totalPages: number;
  totalDocs: number;
}

const model_name = "post";
@Module
export default class PostSearchModule
  extends VuexModule
  implements PostSearchData
{
  errors = [];
  post_search = {} as PostSearch;
  listDataProvince = [];
  listData = [];
  totalPages = 0;
  totalDocs = 0;

  get getTotalPostSearch(): number {
    return this.totalDocs;
  }

  get getPostSearchPages(): number {
    return this.totalPages;
  }

  get getPostSearchErrors(): Array<string> {
    return this.errors;
  }

  get listDataPostSearch(): Array<PostSearch> {
    return this.listData;
  }

  get currentPostSearch(): PostSearch {
    return this.post_search;
  }

  @Mutation
  [Mutations.SET_ERROR_POST_SEARCH](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CURRENT_POST_SEARCH](data) {
    this.post_search = data;
  }

  @Mutation
  [Mutations.SET_POST_SEARCH_DATA](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }

  @Action
  [Actions.GET_ALL_POST_SEARCH](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get(model_name + "/search-link", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_POST_SEARCH_DATA, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_POST_SEARCH_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get(model_name, "get-search-link?link=" + id)
        .then(({ data }) => {
          const data_ = { ...PostSearchDefault, ...data.data };
          this.context.commit(Mutations.SET_CURRENT_POST_SEARCH, data_);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_POST_SEARCH](payload) {
    ApiService.setHeader();
    ApiService.setAdminPermission();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(model_name + "/update-search-link", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_POST_SEARCH, data.data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject(response);
        });
    });
  }

  @Action
  [Actions.DELETE_POST_SEARCH](payload) {
    ApiService.setHeader();
    ApiService.setAdminPermission();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(model_name + "/delete/" + payload.id + "?force=true")
        .then(() => {
          this.context.dispatch(
            Actions.GET_ALL_POST_SEARCH,
            payload.searchString
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
