enum Actions {
  // action types

  GET_ALL_PROVINCE = "GET_ALL_PROVINCE",
  UPDATE_NOTIFICATION_SETTING = "updateNotificationSetting",
  UPDATE_AREA_SALE = "updateSaleArea",
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  VERIFY_EMAIL = "verifyEmail",
  GET_CURRENT_USER = "GET_CURRENT_USER",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_PROFILE = "UPDATE_PROFILE",
  FORGOT_PASSWORD = "forgotPassword",
  CHANGE_PASSWORD = "changePassword",
  UPDATE_PHONE = "UPDATE_PHONE",
  ADD_AREA_SALE = "ADD_AREA_SALE",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  GET_DATA_MEDIA = "GET_DATA_MEDIA",
  UPDATE_USER = "UPDATE_USER",
  //Project
  GET_ALL_PROJECT = "GET_ALL_PROJECT",
  ADD_PROJECT = "ADD_PROJECT",
  GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL",
  UPDATE_PROJECT = "UPDATE_PROJECT",
  DELETE_PROJECT = "DELETE_PROJECT",
  //VALUATION
  GET_ALL_VALUATION = "GET_ALL_VALUATION",
  ADD_VALUATION = "ADD_VALUATION",
  GET_VALUATION_DETAIL = "GET_VALUATION_DETAIL",
  UPDATE_VALUATION = "UPDATE_VALUATION",
  GET_TOTAL_PENDING_VALUATION = "GET_TOTAL_PENDING_VALUATION",

  DELETE_VALUATION = "DELETE_VALUATION",
  //AGENCY
  GET_ALL_AGENCY = "GET_ALL_AGENCY",
  ADD_AGENCY = "ADD_AGENCY",
  GET_AGENCY_DETAIL = "GET_AGENCY_DETAIL",
  UPDATE_AGENCY = "UPDATE_AGENCY",
  DELETE_AGENCY = "DELETE_AGENCY",
  //PARTNER
  GET_ALL_PARTNER = "GET_ALL_PARTNER",
  ADD_PARTNER = "ADD_PARTNER",
  GET_PARTNER_DETAIL = "GET_PARTNER_DETAIL",
  UPDATE_PARTNER = "UPDATE_PARTNER",
  DELETE_PARTNER = "DELETE_PARTNER",
  //EXPERIENCE
  GET_ALL_EXPERIENCE = "GET_ALL_EXPERIENCE",
  ADD_EXPERIENCE = "ADD_EXPERIENCE",
  GET_EXPERIENCE_DETAIL = "GET_EXPERIENCE_DETAIL",
  UPDATE_EXPERIENCE = "UPDATE_EXPERIENCE",
  DELETE_EXPERIENCE = "DELETE_EXPERIENCE",
  // NEWS CATEGORIES
  GET_ALL_NEWS_CATEGORY = "GET_ALL_NEWS_CATEGORY",
  ADD_NEWS_CATEGORY = "ADD_NEWS_CATEGORY",
  GET_NEWS_CATEGORY_DETAIL = "GET_NEWS_CATEGORY_DETAIL",
  UPDATE_NEWS_CATEGORY = "UPDATE_NEWS_CATEGORY",
  DELETE_NEWS_CATEGORY = "DELETE_NEWS_CATEGORY",
  // PARTNERS CATEGORIES
  GET_ALL_PARTNERS_CATEGORY = "GET_ALL_PARTNERS_CATEGORY",
  ADD_PARTNER_CATEGORY = "ADD_PARTNERS_CATEGORY",
  GET_PARTNERS_CATEGORY_DETAIL = "GET_PARTNERS_CATEGORY_DETAIL",
  UPDATE_PARTNER_CATEGORY = "UPDATE_PARTNERS_CATEGORY",
  DELETE_PARTNER_CATEGORY = "DELETE_PARTNERS_CATEGORY",
  // PROJECT CATEGORIES
  GET_ALL_PROJECT_CATEGORY = "GET_ALL_PROJECT_CATEGORY",
  ADD_PROJECT_CATEGORY = "ADD_PROJECT_CATEGORY",
  GET_PROJECT_CATEGORY_DETAIL = "GET_PROJECT_CATEGORY_DETAIL",
  UPDATE_PROJECT_CATEGORY = "UPDATE_PROJECT_CATEGORY",
  DELETE_PROJECT_CATEGORY = "DELETE_PROJECT_CATEGORY",
  UPDATE_CONFIGS = "UPDATE_CONFIGS",
  // PRODUCT CATEGORIES
  GET_ALL_PRODUCT_CATEGORY = "GET_ALL_PRODUCT_CATEGORY",
  ADD_PRODUCT_CATEGORY = "ADD_PRODUCT_CATEGORY",
  GET_PRODUCT_CATEGORY_DETAIL = "GET_PRODUCT_CATEGORY_DETAIL",
  UPDATE_PRODUCT_CATEGORY = "UPDATE_PRODUCT_CATEGORY",
  DELETE_PRODUCT_CATEGORY = "DELETE_PRODUCT_CATEGORY",
  // POST_SEARCH
  GET_ALL_POST_SEARCH = "GET_ALL_POST_SEARCH",
  ADD_POST_SEARCH = "ADD_POST_SEARCH",
  GET_POST_SEARCH_DETAIL = "GET_POST_SEARCH_DETAIL",
  UPDATE_POST_SEARCH = "UPDATE_POST_SEARCH",
  DELETE_POST_SEARCH = "DELETE_POST_SEARCH",
}
enum Mutations {
  //POST_SEARCH
  SET_ERROR_POST_SEARCH = "SET_ERROR_POST_SEARCH",
  SET_POST_SEARCH_DATA = "SET_POST_SEARCH_DATA",
  SET_CURRENT_POST_SEARCH = "SET_CURRENT_POST_SEARCH",
  SET_PARENT_POST_SEARCH = "SET_PARENT_POST_SEARCH",
  //NEWS CATEGORIES
  SET_PROVINCE_DATA = "SET_PROVINCE_DATA",
  SET_ERROR_NEWS_CATEGORY = "SET_ERROR_NEWS_CATEGORY",
  SET_ALL_NEWS_CATEGORIES_DATA = "SET_ALL_NEWS_CATEGORIES_DATA",
  SET_CURRENT_NEWS_CATEGORY = "SET_CURRENT_NEWS_CATEGORY",
  SET_PARENT_CATEGORY = "SET_PARENT_CATEGORY",
  //PROJECT CATEGORIES
  SET_ERROR_PROJECT_CATEGORY = "SET_ERROR_PROJECT_CATEGORY",
  SET_ALL_PROJECT_CATEGORIES_DATA = "SET_ALL_PROJECT_CATEGORIES_DATA",
  SET_CURRENT_PROJECT_CATEGORY = "SET_CURRENT_PROJECT_CATEGORY",
  SET_PARENT_PROJECT_CATEGORY = "SET_PARENT_PROJECT_CATEGORY",
  //BROKER_REQUEST_UPGRADE
  SET_ERROR_BROKER_REQUEST_UPGRADE = "SET_ERROR_BROKER_REQUEST_UPGRADE",
  SET_TOTAL_PENDING_BROKER_REQ_UPGRADE = "SET_TOTAL_PENDING_BROKER_REQ_UPGRADE",
  SET_ALL_BROKER_REQUEST_UPGRADE_DATA = "SET_ALL_BROKER_REQUEST_UPGRADE_DATA",
  SET_CURRENT_BROKER_REQUEST_UPGRADE = "SET_CURRENT_BROKER_REQUEST_UPGRADE",
  //POST_REGISTER
  SET_ERROR_POST_REGISTER = "SET_ERROR_POST_REGISTER",
  SET_TOTAL_PENDING_POST_REGISTER = "SET_TOTAL_PENDING_POST_REGISTER",
  SET_ALL_POST_REGISTER_DATA = "SET_ALL_POST_REGISTER_DATA",
  SET_CURRENT_POST_REGISTER = "SET_CURRENT_POST_REGISTER",
  //CURRICULUM_VITAE
  SET_ERROR_CURRICULUM_VITAE = "SET_ERROR_CURRICULUM_VITAE",
  SET_TOTAL_PENDING_CURRICULUM_VITAE = "SET_TOTAL_PENDING_CURRICULUM_VITAE",
  SET_ALL_CURRICULUM_VITAE_DATA = "SET_ALL_CURRICULUM_VITAE_DATA",
  SET_CURRENT_CURRICULUM_VITAE = "SET_CURRENT_CURRICULUM_VITAE",
  //PRODUCT CATEGORIES
  SET_ERROR_PRODUCT_CATEGORY = "SET_ERROR_PRODUCT_CATEGORY",
  SET_ALL_PRODUCT_CATEGORIES_DATA = "SET_ALL_PRODUCT_CATEGORIES_DATA",
  SET_CURRENT_PRODUCT_CATEGORY = "SET_CURRENT_PRODUCT_CATEGORY",
  SET_PARENT_PRODUCT_CATEGORY = "SET_PARENT_PRODUCT_CATEGORY",
  // PARTNERS CATEGORIES
  SET_ERROR_PARTNER_CATEGORY = "SET_ERROR_PARTNER_CATEGORY",
  SET_ALL_PARTNER_CATEGORIES_DATA = "SET_ALL_PARTNER_CATEGORIES_DATA",
  SET_CURRENT_PARTNER_CATEGORY = "SET_CURRENT_PARTNER_CATEGORY",
  SET_PARTNER_CATEGORY = "SET_PARENT_PARTNER_CATEGORY",
  // PARTNERS CATEGORIES
  SET_ERROR_PARTNER = "SET_ERROR_PARTNER",
  SET_ALL_PARTNER_DATA = "SET_ALL_PARTNER_DATA",
  SET_CURRENT_PARTNER = "SET_CURRENT_PARTNER",
  SET_PARTNER_DATA = "SET_PARTNER_DATA",

  CLOSE_MEDIA = "CLOSE_MEDIA",
  SHOW_MEDIA = "SHOW_MEDIA",
  SELECT_SINGLE_MEDIA = "SELECT_SINGLE_MEDIA",
  SELECT_MULTIPLE_MEDIA = "SELECT_MULTIPLE_MEDIA",
  SET_ACTION_CALLBACK = "SET_ACTION_CALLBACK",
  SET_ACTION_CALL = "SET_ACTION_CALL",
  RESET_ACTION_MEDIA = "RESET_ACTION_MEDIA",
  SET_ROLES = "SET_ROLES",
  SET_SELECT_MEDIA = "SET_SELECT_MEDIA",
  SET_SELECT_MULTI_MEDIA = "SET_SELECT_MULTI_MEDIA",
  // AUTHENTICATION
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_ADMINISTRATORS = "SET_ADMINISTRATORS",
  SET_AUTH = "setAuth",
  SET_TOKEN_AUTH = "SET_TOKEN_AUTH",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  //Project
  SET_PROJECTS_DATA = "SET_PROJECTS_DATA",
  SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT",
  SET_ERROR_PROJECT = "SET_ERROR_PROJECT",
  //VALUATION
  SET_VALUATIONS_DATA = "SET_VALUATIONS_DATA",
  SET_TOTAL_VALUATION = "SET_TOTAL_VALUATION",
  SET_CURRENT_VALUATION = "SET_CURRENT_VALUATION",
  SET_ERROR_VALUATION = "SET_ERROR_VALUATION",
  //_EXPERIENCE
  SET_EXPERIENCES_DATA = "SET_EXPERIENCES_DATA",
  SET_CURRENT_EXPERIENCE = "SET_CURRENT_EXPERIENCE",
  SET_ERROR_EXPERIENCE = "SET_ERROR_EXPERIENCE",
  //AGENCY
  SET_AGENCY_DATA = "SET_AGENCY_DATA",
  SET_CURRENT_AGENCY = "SET_CURRENT_AGENCY",
  SET_ERROR_AGENCY = "SET_ERROR_AGENCY",
}

enum ActionsConfigs {
  GET_ALL_CATEGORY = "GET_ALL_CATEGORY",
  ACTION_ADD_CATEGORY = "ACTION_ADD_CATEGORY",
  GET_CATEGORY_DETAIL = "GET_CATEGORY_DETAIL",
  UPDATE_CATEGORY = "UPDATE_CATEGORY",
  DELETE_CATEGORY = "DELETE_CATEGORY",
  GET_MOTHER_CATEGORY = "GET_MOTHER_CATEGORY",
  GET_INDEPENDENT_BROKER = "GET_INDEPENDENT_BROKER",
  //BROKER_REQUEST_UPGRADE
  GET_ALL_BROKER_REQUEST_UPGRADE = "GET_ALL_BROKER_REQUEST_UPGRADE",
  GET_TOTAL_PENDING_BROKER_REQUEST_UPGRADE = "GET_TOTAL_PENDING_BROKER_REQUEST_UPGRADE",
  GET_BROKER_REQUEST_UPGRADE_DETAIL = "GET_BROKER_REQUEST_UPGRADE_DETAIL",
  UPDATE_BROKER_REQUEST_UPGRADE = "UPDATE_BROKER_REQUEST_UPGRADE",
  DELETE_BROKER_REQUEST_UPGRADE = "DELETE_BROKER_REQUEST_UPGRADE",
  //POST_REGISTER
  GET_ALL_POST_REGISTER = "GET_ALL_POST_REGISTER",
  GET_TOTAL_PENDING_POST_REGISTER = "GET_TOTAL_PENDING_POST_REGISTER",
  GET_POST_REGISTER_DETAIL = "GET_POST_REGISTER_DETAIL",
  UPDATE_POST_REGISTER = "UPDATE_POST_REGISTER",
  DELETE_POST_REGISTER = "DELETE_POST_REGISTER",
  //CURRICULUM_VITAE
  GET_ALL_CURRICULUM_VITAE = "GET_ALL_CURRICULUM_VITAE",
  GET_TOTAL_PENDING_CURRICULUM_VITAE = "GET_TOTAL_PENDING_CURRICULUM_VITAE",
  GET_CURRICULUM_VITAE_DETAIL = "GET_CURRICULUM_VITAE_DETAIL",
  UPDATE_CURRICULUM_VITAE = "UPDATE_CURRICULUM_VITAE",
  DELETE_CURRICULUM_VITAE = "DELETE_CURRICULUM_VITAE",
  GET_ALL_BROKER = "GET_ALL_BROKER",
  //DEPARTMENT
  GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT",
  ACTION_ADD_DEPARTMENT = "ACTION_ADD_DEPARTMENT",
  GET_DEPARTMENT_DETAIL = "GET_DEPARTMENT_DETAIL",
  UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT",
  DELETE_DEPARTMENT = "DELETE_DEPARTMENT",
  //LEVEL_MANAGEMENT
  GET_ALL_LEVEL_MANAGEMENT = "GET_ALL_LEVEL_MANAGEMENT",
  ACTION_ADD_LEVEL_MANAGEMENT = "ACTION_ADD_LEVEL_MANAGEMENT",
  GET_LEVEL_MANAGEMENT_DETAIL = "GET_LEVEL_MANAGEMENT_DETAIL",
  UPDATE_LEVEL_MANAGEMENT = "UPDATE_LEVEL_MANAGEMENT",
  DELETE_LEVEL_MANAGEMENT = "DELETE_LEVEL_MANAGEMENT",
  // action Furniture
  GET_ALL_FURNITURE = "GET_ALL_FURNITURE",
  GET_FURNITURE_DETAIL = "GET_FURNITURE_DETAIL",
  ACTION_ADD_FURNITURE = "ACTION_ADD_FURNITURE",
  UPDATE_FURNITURE = "UPDATE_FURNITURE",
  DELETE_FURNITURE = "DELETE_FURNITURE",
  GET_PROVINCES = "get_all_provinces",
  GET_FURNITURE_CONFIGS = "GET_FURNITURE_CONFIGS",
  GET_UNIT_PRICE = "get_unitPrice",
  GET_STATUS_QUO = "get_status_quo",
  GET_ROOM = "get_room",
  GET_DIRECTION = "get_direction",
  GET_FLOOR = "get_floor",
  GET_ROAD_WIDTH = "get_road_width",
  GET_PRICE_TYPE = "get_price_type",
  GET_ACREAGE = "get_acreage",
  GET_PRODUCT_TYPE = "get_type",
  GET_PRODUCT_CATEGORIES = "GET_PRODUCT_CATEGORIES",
  GET_POST_TYPE = "GET_POST_TYPE",
  GET_ACCOUNT_TYPE = "GET_ACCOUNT_TYPE",
}
enum MutationsConfigs {
  SET_ERROR_CATEGORY = "SET_ERROR_CATEGORY",
  SET_ALL_CATEGORY_DATA = "SET_ALL_CATEGORY_DATA",
  SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY",
  SET_MOTHER_CATEGORY = "SET_MOTHER_CATEGORY",
  //Project
  SET_ERROR_PROJECT = "SET_ERROR_PROJECT",
  SET_ALL_PROJECT_DATA = "SET_ALL_PROJECT_DATA",
  SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT",

  SET_CURRENT_INDEPENDENT_BROKER = "SET_CURRENT_INDEPENDENT_BROKER",

  SET_CURRENT_DEPARTMENT = "SET_CURRENT_DEPARTMENT",
  SET_ALL_DEPARTMENT_DATA = "SET_ALL_DEPARTMENT_DATA",

  SET_CURRENT_LEVEL_MANAGEMENT = "SET_CURRENT_LEVEL_MANAGEMENT",
  SET_ERROR_LEVEL_MANAGEMENT = "SET_ERROR_LEVEL_MANAGEMENT",
  SET_ALL_LEVEL_MANAGEMENT_DATA = "SET_ALL_LEVEL_MANAGEMENT_DATA",

  SET_CURRENT_BROKER = "SET_CURRENT_BROKER",
  SET_CURRENT_FURNITURE = "SET_CURRENT_FURNITURE",
  SET_ERROR_DEPARTMENT = "SET_ERROR_DEPARTMENT",
  GET_ALL_FURNITURE_DATA = "GET_FURNITURE_DATA",
  SET_ERROR_FURNITURE = "SET_ERROR_FURNITURE",
  GET_PROVINCES = "get_all_provinces",
  SET_PROVINCES = "set_all_provinces",
  SET_ERROR_CONFIG_BOOKING = "SET_ERROR_CONFIG_BOOKING",
  GET_UNIT_PRICE = "get_unitPrice",
  GET_STATUS_QUO = "get_status_quo",
  GET_ROOM = "get_room",
  GET_PRODUCT_CATEGORIES = "GET_PRODUCT_CATEGORIES",
  GET_DIRECTION = "get_direction",
  GET_FLOOR = "get_floor",
  GET_ROAD_WIDTH = "get_road_width",
  GET_PRICE_TYPE = "get_price_type",
  GET_ACREAGE = "get_acreage",
  GET_PRODUCT_TYPE = "get_product_type",
  GET_FURNITURE = "get_furniture",
  GET_POST_TYPE = "GET_POST_TYPE",
  GET_ACCOUNT_TYPE = "GET_ACCOUNT_TYPE",
  SET_UNIT_PRICE = "SET_unitPrice",
  SET_FURNITURE_CONFIGS = "SET_FURNITURE_CONFIGS",
  SET_STATUS_QUO = "SET_status_quo",
  SET_ROOM = "SET_room",
  SET_DIRECTION = "SET_direction",
  SET_FLOOR = "SET_floor",
  SET_ROAD_WIDTH = "SET_road_width",
  SET_PRICE_TYPE = "SET_price_type",
  SET_ACREAGE = "SET_acreage",
  SET_PRODUCT_TYPE = "SET_PRODUCT_TYPE",
  SET_PRODUCT_CATEGORIES = "SET_PRODUCT_CATEGORIES",
  SET_ACCOUNT_TYPE = "SET_ACCOUNT_TYPE",
  SET_POST_TYPE = "SET_POST_TYPE",
}
enum LocalStoreKeyConfig {
  ACCOUNT_TYPE = "ACCOUNT_TYPE",
  POST_TYPE = "POST_TYPE",
  PRODUCT_CATEGORIES = "PRODUCT_CATEGORIES",
  PROVINCES = "PROVINCES",
  FURNITURE_CONFIGS = "FURNITURE_CONFIGS",
  UNIT_PRICE = "unitPrice",
  STATUS_QUO = "status-quo",
  ROOM = "room",
  DIRECTION = "direction",
  FLOOR = "floor",
  ROAD_WIDTH = "road-width",
  PRICE_TYPE = "price_type",
  ACREAGE = "acreage",
  PRODUCT_TYPE = "product_type",
}
const ROAD_WIDTH = ["Duới 2m", "2 - 3,5m", "3,5 - 5m", "5m - 7m", "Trên 7m"];

export {
  ActionsConfigs,
  MutationsConfigs,
  ROAD_WIDTH,
  LocalStoreKeyConfig,
  Actions,
  Mutations,
};
