import ApiService from "@/core/services/ApiService";
import { Actions, ActionsConfigs, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { ValuationModel } from "@/store/model/Valuation";

export interface ValuationData {
  errors: Array<string>;
  valuation: ValuationModel;
  listData: Array<ValuationModel>;
  totalPages: number;
  totalDocs: number;
}
@Module
export default class ValuationModule
  extends VuexModule
  implements ValuationData
{
  errors = [];
  valuation = {} as ValuationModel;
  listData = [];
  totalPages = 0;
  totalDocs = 0;
  totalPending = 0;
  get getTotalValuations(): number {
    return this.totalDocs;
  }
  get getValuationPages(): number {
    return this.totalPages;
  }
  get getValuationPending(): number {
    return this.totalPending;
  }
  get getValuationErrors(): Array<string> {
    return this.errors;
  }
  get listDataValuations(): Array<ValuationModel> {
    return this.listData;
  }
  get currentValuation(): ValuationModel {
    return this.valuation;
  }
  @Mutation
  [Mutations.SET_TOTAL_VALUATION](total) {
    this.totalPending = total;
  }
  @Mutation
  [Mutations.SET_ERROR_VALUATION](error) {
    this.errors = error;
  }
  @Mutation
  [Mutations.SET_CURRENT_VALUATION](data) {
    this.valuation = data;
  }
  @Mutation
  [Mutations.SET_VALUATIONS_DATA](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }
  @Action
  [Actions.GET_ALL_VALUATION](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("valuation", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_VALUATIONS_DATA, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_VALUATION,
            response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_VALUATION_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("valuation/show", id)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_VALUATION", data.data);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit("SET_VALUATION_ERROR", response.data.message);
          reject();
        });
    });
  }
  @Action
  [Actions.ADD_VALUATION](credentials) {
    // delete credentials['_id'];
    // credentials['status'] = "pending"
    // delete credentials['broker_approved']
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("valuation/create", credentials)
        .then(({ data }) => {
          // this.context.commit("SET_CURRENT_VALUATION", data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit("SET_VALUATION_ERROR", response.data.message);
          reject();
        });
    });
  }
  @Action
  [Actions.GET_TOTAL_PENDING_VALUATION](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get("valuation", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_TOTAL_VALUATION, data.totalDocs);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_POST_REGISTER,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.UPDATE_VALUATION](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("valuation/update-status", payload)
        .then(({ data }) => {
          // this.context.commit("SET_CURRENT_VALUATION", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit("SET_VALUATION_ERROR", response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_VALUATION](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("valuation/delete/" + payload.id)
        .then(() => {
          this.context.dispatch("GET_VALUATIONS_DATA", payload.searchString);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit("SET_VALUATION_ERROR", response.data.errors);
          reject();
        });
    });
  }
}
