import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {
  productCategoryDefault,
  ProductCategoryModel,
} from "@/store/model/ProductCategory";
const routerBaseAPI = "category";

export interface ProductCategoryData {
  errors: Array<string>;
  listMotherProductCategory: Array<ProductCategoryModel>;
  listProductCategory: Array<ProductCategoryModel>;
  currentProductCategory: ProductCategoryModel;
  totalPages: number;
  totalDocs: number;
}
@Module
export default class ProductCategoryModule
  extends VuexModule
  implements ProductCategoryData
{
  errors = [];
  listMotherProductCategory = [];
  listProductCategory = [];
  currentProductCategory = {} as ProductCategoryModel;
  totalPages = 0;
  totalDocs = 0;
  get getTotalProductCategory(): number {
    return this.totalDocs;
  }
  get getTotalPagesProductCategory(): number {
    return this.totalPages;
  }
  get getErrorsProductCategory(): Array<string> {
    return this.errors;
  }
  get getCurrentProductCategory(): ProductCategoryModel {
    return this.currentProductCategory;
  }
  get listDataProductCategory(): Array<ProductCategoryModel> {
    return this.listProductCategory;
  }
  get getListMotherProductCategory(): Array<ProductCategoryModel> {
    return this.listMotherProductCategory;
  }
  @Mutation
  [Mutations.SET_ERROR_PRODUCT_CATEGORY](error) {
    this.errors = error;
  }
  @Mutation
  [Mutations.SET_PARENT_CATEGORY](data) {
    this.listMotherProductCategory = data;
  }
  @Mutation
  [Mutations.SET_ALL_PRODUCT_CATEGORIES_DATA](data) {
    this.listProductCategory = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }
  @Mutation
  [Mutations.SET_CURRENT_PRODUCT_CATEGORY](data) {
    this.currentProductCategory = { ...productCategoryDefault, ...data };
  }
  @Action
  [Actions.GET_ALL_PRODUCT_CATEGORY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get(routerBaseAPI, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_PRODUCT_CATEGORIES_DATA, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PRODUCT_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.ADD_PRODUCT_CATEGORY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();

      ApiService.post(routerBaseAPI + "/create", params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_PRODUCT_CATEGORY, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PRODUCT_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.GET_PRODUCT_CATEGORY_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();

      ApiService.get(routerBaseAPI + "/show", id)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_CURRENT_PRODUCT_CATEGORY,
            data.data
          );
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PRODUCT_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
  @Action
  [Actions.UPDATE_PRODUCT_CATEGORY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();

      ApiService.put(routerBaseAPI + "/update", params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PRODUCT_CATEGORY,
            response.data.message
          );
          reject(response.data.message);
        });
    });
  }
  @Action
  [Actions.DELETE_PRODUCT_CATEGORY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.delete(routerBaseAPI + "/delete/" + payload.id)
        .then(() => {
          this.context.dispatch(
            Actions.GET_ALL_PRODUCT_CATEGORY,
            payload.searchString
          );
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PRODUCT_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }
}
