import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Partner } from "@/store/model/Partner";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface PartnerData {
  errors: Array<string>;
  partner: Partner;
  listData: Array<Partner>;
  totalPages: number;
  totalDocs: number;
}
const model_name = "partner";
@Module
export default class PartnerModule extends VuexModule implements PartnerData {
  errors = [];
  partner = {} as Partner;
  listData = [];
  totalPages = 0;
  totalDocs = 0;

  get getTotalPartner(): number {
    return this.totalDocs;
  }
  get getPartnerPages(): number {
    return this.totalPages;
  }
  get getPartnerErrors(): Array<string> {
    return this.errors;
  }

  get listDataPartner(): Array<Partner> {
    return this.listData;
  }

  get currentPartner(): Partner {
    return this.partner;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CURRENT_PARTNER](data) {
    this.partner = data;
  }

  @Mutation
  [Mutations.SET_PARTNER_DATA](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }

  @Action
  [Actions.GET_ALL_PARTNER](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(model_name, params)
        .then(({ data }) => {
          this.context.commit("SET_PARTNER_DATA", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PARTNER_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(model_name + "/show", id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_PARTNER, data.data);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_PARTNER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(model_name + "/create", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_PARTNER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PARTNER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(model_name + "/update", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_PARTNER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PARTNER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(model_name + "/delete/" + payload.id)
        .then(() => {
          this.context.dispatch(Actions.GET_ALL_PARTNER, payload.searchString);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
