import { SeoDefault, SeoModel } from "@/store/model";

export interface PostSearch {
  _id?: string;
  title: string;
  link: string;
  description: string;
  content: string;
  seo?: SeoModel;
}

export const PostSearchDefault: PostSearch = {
  _id: "",
  title: "",
  link: "",
  description: "",
  content: "",
  seo: SeoDefault,
};
