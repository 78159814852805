import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import PermissionModule from "@/store/modules/accounts/PermissionsModule";
import RolesModule from "@/store/modules/accounts/RolesModule";
import AccountsModule from "@/store/modules/AccountsModule";
import NewsModule from "@/store/modules/NewsModule";
import NewsCategoryModule from "@/store/modules/NewsCategoryModule";
import MediaModule from "@/store/modules/MediaModule";
import ProjectModule from "@/store/modules/ProjectModule";
import ProjectCategoryModule from "@/store/modules/ProjectCategoryModule";
import ProductCategoryModule from "@/store/modules/ProductCategoryModule";
import ExperienceModule from "@/store/modules/ExperienceModule";
import PartnerCategoryModule from "@/store/modules/PartnerCategoryModule";
import AgencyModule from "@/store/modules/AgencyModule";
import PartnerModule from "@/store/modules/PartnerModule";
import ValuationModule from "@/store/modules/ValuationModule";
import ServiceModule from "@/store/modules/ServiceModule";
import PostRegisterModule from "@/store/modules/PostRegisterModule";
import PostSearchModule from "@/store/modules/PostSearchModule";
import BrokerRequestUpgradeModule from "@/store/modules/BrokerRequestUpgradeModule";
import CurriculumVitaeModule from "@/store/modules/CurriculumVitaeModule";
config.rawError = true;

const store = createStore({
  modules: {
    AccountsModule,
    AgencyModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    DataFormModule: PostRegisterModule,
    ExperienceModule,
    MediaModule,
    NewsModule,
    NewsCategoryModule,
    ProjectModule,
    PermissionModule,
    ProjectCategoryModule,
    ProductCategoryModule,
    PartnerCategoryModule,
    RolesModule,
    ServiceModule,
    PartnerModule,
    ValuationModule,
    PostSearchModule,
    BrokerRequestUpgradeModule,
    CurriculumVitaeModule,
  },
});

export default store;
