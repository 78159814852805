import {
  image,
  location,
  locationDefault,
  master_plan,
  master_planDefault,
} from "@/store/model";
import { default_image } from "@/core/config/AppConfig";
import { date } from "yup";

export interface Project {
  _id?: string;
  name: string;
  slug: string;
  short_desc: string;
  content: string;
  thumbnail: image;
  categories: string[];
  status: string;
  user_id: string;
  banner: {
    image: image;
    text: string;
  };
  seo: {
    robot: string;
    title: string;
    keywords: string;
    description: string;
  };
  payment_schedule: string;
  payment_schedule_image?: image;
  policy_image?: image;
  home: boolean;
  address: string;
  galleries: image[];
  images_apartment: image[];
  utility: {
    images: image[];
    text: string;
  };
  published_at: Date;
  utility_area: {
    image: image;
    text: string;
  };
  location: location;
  place: {
    image: image;
    text: string;
  };
  price_max: number;
  price_min: number;
  acreage: number;
  count_view: number;
  unit_price: {
    key: string;
    value: number;
    text: string;
  };
  master_plan: master_plan;
  video: string;
}
export const ProjectDefault: Project = {
  name: "",
  slug: "",
  short_desc: "",
  content: "",
  thumbnail: default_image,
  categories: [] as string[],
  status: "active",
  user_id: "",
  banner: {
    image: default_image,
    text: "",
  },
  seo: {
    robot: "index, follow",
    title: "",
    keywords: "",
    description: "",
  },
  payment_schedule: "",
  home: false,
  address: "",
  galleries: [] as image[],
  images_apartment: [] as image[],
  utility: {
    images: [] as image[],
    text: "",
  },
  published_at: {} as Date,
  utility_area: {
    image: default_image,
    text: "",
  },
  location: locationDefault,
  place: {
    image: default_image,
    text: "",
  },
  price_max: 0,
  price_min: 0,
  acreage: 0,
  count_view: 0,
  unit_price: {
    key: "trieu/m2",
    value: 1000000,
    text: "triệu/m2",
  },
  master_plan: master_planDefault,
  video: "",
};
