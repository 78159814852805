import { default_image } from "@/core/config/AppConfig";
import { Project } from "@/store/model/Project";

export interface image {
  path?: string;
  name?: string;
  default?: boolean;
}

export interface location {
  street_id: string | null;
  street_name: string;
  ward_id: string | null;
  ward_name: string;
  district_id: string | null;
  district_name: string;
  province_id: string | null;
  province_name: string;
  latitude?: string;
  longitude?: string;
}

export interface apartment {
  name: string;
  image: image;
}
export interface menu {
  name: string;
  id: number;
  url: string;
}

export interface building {
  name: string;
  apartments: apartment[];
}

export interface master_plan {
  description: string;
  buildings: building[];
}

export interface content {
  title: string;
  description: string;
}

export const contentDefault: content = {
  title: "",
  description: "",
};

export const apartmentDefault: apartment = {
  name: "",
  image: default_image,
};

export const buildingDefault: building = {
  name: "",
  apartments: [],
};

export const master_planDefault: master_plan = {
  description: "",
  buildings: [],
};

export const locationDefault: location = {
  street_id: null,
  street_name: "",
  ward_id: null,
  ward_name: "",
  district_id: null,
  district_name: "",
  province_id: null,
  province_name: "",
};

export interface phone {
  number: string;
  verify: never;
  receive: never;
}

export interface address {
  province: string;
  district: string;
  ward: string;
  street: string;
  address: string;
}

export interface area_sale {
  district: location[];
  province_id: string;
  province_name: string;
}

export interface objectNotification {
  created_at: string;
  email: string;
  message: string;
  name: string;
  user_id: string;
  _id: string;
}

export interface AreaObject {
  id: string;
  name: string;
}

export interface NotificationArea {
  ward: AreaObject[];
  district: AreaObject[];
  province: AreaObject[];
}

export interface NotificationCategory {
  type: string[];
  product_category: string[];
}

export interface NotificationSetting {
  area: NotificationArea;
  category: NotificationCategory;
  detail: {
    comment: boolean;
    booking: boolean;
    house_viewing: boolean;
    new_booking: boolean;
  };
  notification_methods: {
    email: {
      status: boolean;
      value: string;
    };
    phone: {
      status: boolean;
      value: string;
    };
  };
}

export interface AdminModel {
  _id: string;
  name: string;
  birthday: string;
  email: string;
  password: any;
  status: string;
  created_at: string;
  avatar: image;
  updated_at: string;
}

export interface SeoModel {
  robot: string;
  title: string;
  keywords: string;
  description: string;
}

export const SeoDefault: SeoModel = {
  robot: "",
  title: "",
  keywords: "",
  description: "",
};
export const AdminDefault: AdminModel = {
  _id: "",
  name: "",
  birthday: "",
  avatar: default_image,
  email: "",
  password: "",
  created_at: "",
  updated_at: "",
  status: "active",
};
