import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Agency } from "@/store/model/Agency";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface AgencyData {
  errors: Array<string>;
  partner: Agency;
  listData: Array<Agency>;
  totalPages: number;
  totalDocs: number;
}
const model_name = "agency";
@Module
export default class AgencyModule extends VuexModule implements AgencyData {
  errors = [];
  partner = {} as Agency;
  listData = [];
  totalPages = 0;
  totalDocs = 0;

  get getTotalAgency(): number {
    return this.totalDocs;
  }
  get getAgencyPages(): number {
    return this.totalPages;
  }
  get getAgencyErrors(): Array<string> {
    return this.errors;
  }

  get listDataAgency(): Array<Agency> {
    return this.listData;
  }

  get currentAgency(): Agency {
    return this.partner;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CURRENT_AGENCY](data) {
    this.partner = data;
  }

  @Mutation
  [Mutations.SET_AGENCY_DATA](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }

  @Action
  [Actions.GET_ALL_AGENCY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(model_name, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AGENCY_DATA, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_AGENCY_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(model_name + "/show", id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_AGENCY, data.data);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_AGENCY](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(model_name + "/create", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_AGENCY, data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_AGENCY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(model_name + "/update", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_AGENCY, data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_AGENCY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(model_name + "/delete/" + payload.id)
        .then(() => {
          this.context.dispatch(Actions.GET_ALL_AGENCY, payload.searchString);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
