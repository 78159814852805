import ApiService from "@/core/services/ApiService";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { Experience } from "@/store/model/Experience";

export interface image {
  name: string;
  path: string;
}

export interface ExperienceData {
  errors: Array<string>;
  experience: Experience;
  listData: Array<Experience>;
  totalPages: number;
  totalDocs: number;
}
const model_name = "experience";
@Module
export default class ExperienceModule
  extends VuexModule
  implements ExperienceData
{
  errors = [];
  experience = {} as Experience;
  listData = [];
  totalPages = 0;
  totalDocs = 0;

  get getTotalExperience(): number {
    return this.totalDocs;
  }
  get getExperiencePages(): number {
    return this.totalPages;
  }
  get getExperienceErrors(): Array<string> {
    return this.errors;
  }

  get listDataExperience(): Array<Experience> {
    return this.listData;
  }

  get currentExperience(): Experience {
    return this.experience;
  }

  @Mutation
  [Mutations.SET_ERROR_EXPERIENCE](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CURRENT_EXPERIENCE](data) {
    this.experience = data;
  }

  @Mutation
  [Mutations.SET_EXPERIENCES_DATA](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }

  @Action
  [Actions.GET_ALL_EXPERIENCE](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get(model_name, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EXPERIENCES_DATA, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.GET_EXPERIENCE_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.get(model_name + "/show", id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_EXPERIENCE, data.data);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_EXPERIENCE](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.setAdminPermission();
      ApiService.post(model_name + "/create", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_EXPERIENCE, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_EXPERIENCE](payload) {
    ApiService.setHeader();
    ApiService.setAdminPermission();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(model_name + "/update", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_EXPERIENCE, data.data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_EXPERIENCE](payload) {
    ApiService.setHeader();
    ApiService.setAdminPermission();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(model_name + "/delete/" + payload.id + "?force=true")
        .then(() => {
          this.context.dispatch("GET_ALL_EXPERIENCE", payload.searchString);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
