import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/manager-media",
    name: "manager-media",
    props: (route) => ({
      type: route.query.type,
    }),
    component: () => import("@/views/Media.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/profile",
        name: "profile",
        redirect: "/profile/information",

        component: () => import("@/views/profile/Account.vue"),
        children: [
          {
            path: "/profile/information",
            name: "profile-information",
            component: () => import("@/views/profile/Information.vue"),
            meta: { isAuthentication: true },
          },
          {
            path: "/profile/password",
            name: "profile-password",
            component: () => import("@/views/profile/PasswordChange.vue"),
            meta: { isAuthentication: true },
          },
        ],
      },
      {
        path: "/403",
        name: "error-403",
        component: () => import("@/views/error/Error403.vue"),
        meta: { isAuthentication: true },
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: { isAuthentication: true },
      },

      {
        path: "administrators/accounts",
        name: "accounts",
        component: () => import("@/views/administrator/accounts/index.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
        meta: { isAuthentication: true, permissions: ["accounts"] },
      },
      {
        path: "administrators/accounts/add",
        name: "accounts-add",
        props: true,
        component: () => import("@/views/administrator/accounts/add.vue"),
        meta: { isAuthentication: true, permissions: ["accounts"] },
      },
      {
        path: "administrators/accounts/edit/:id",
        name: "accounts-edit",
        props: true,
        component: () => import("@/views/administrator/accounts/edit.vue"),
        meta: { isAuthentication: true, permissions: ["accounts"] },
      },
      {
        path: "administrators/permissions",
        name: "permissions",
        component: () => import("@/views/administrator/permissions/index.vue"),
        props: (route) => ({
          name: route.query.name,
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
        }),
        meta: {
          isAuthentication: true,
          permissions: ["administrators", "permissions"],
        },
      },
      {
        path: "administrators/permissions/add",
        name: "permissions-add",
        component: () => import("@/views/administrator/permissions/add.vue"),
        meta: {
          isAuthentication: true,
          permissions: ["administrators", "permissions"],
        },
      },
      {
        path: "administrators/permissions/edit/:id",
        name: "permissions-edit",
        props: true,
        meta: {
          isAuthentication: true,
          permissions: ["administrators", "permissions"],
        },
        component: () => import("@/views/administrator/permissions/edit.vue"),
      },
      {
        path: "administrators/roles",
        name: "roles",
        component: () => import("@/views/administrator/roles/index.vue"),
        props: (route) => ({
          name: route.query.name,
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
        }),
        meta: {
          isAuthentication: true,
          permissions: ["accounts", "permissions"],
        },
      },
      {
        path: "administrators/roles/add",
        name: "roles-add",
        component: () => import("@/views/administrator/roles/add.vue"),
        meta: {
          isAuthentication: true,
          permissions: ["accounts", "permissions"],
        },
      },
      {
        path: "administrators/roles/edit/:id",
        name: "roles-edit",
        props: true,
        component: () => import("@/views/administrator/roles/edit.vue"),
        meta: {
          isAuthentication: true,
          permissions: ["accounts", "permissions"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "contents/news",
        name: "news",
        component: () => import("@/views/news/index.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
      },
      {
        path: "contents/news/add",
        name: "news-add",

        meta: {
          isAuthentication: true,
          permissions: ["news"],
        },
        component: () => import("@/views/news/add.vue"),
      },
      {
        path: "contents/news/edit/:id",
        name: "news-edit",
        meta: {
          isAuthentication: true,
          permissions: ["news"],
        },

        props: true,
        component: () => import("@/views/news/edit.vue"),
      },

      {
        path: "contents/news-categories",
        name: "categories",
        meta: {
          isAuthentication: true,
          permissions: ["news"],
        },
        component: () => import("@/views/news-categories/index.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
      },
      {
        path: "contents/news-categories/add",
        name: "news-categories-add",
        meta: {
          isAuthentication: true,
          permissions: ["news"],
        },

        component: () => import("@/views/news-categories/add.vue"),
      },
      {
        path: "contents/news-categories/edit/:id",
        name: "news-categories-edit",
        meta: {
          isAuthentication: true,
          permissions: ["news"],
        },

        props: true,
        component: () => import("@/views/news-categories/edit.vue"),
      },

      {
        path: "contents/product-categories",
        name: "product-categories",
        meta: {
          isAuthentication: true,
        },
        component: () => import("@/views/product-categories/index.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
      },
      {
        path: "contents/product-categories/edit/:id",
        name: "product-categories-edit",
        meta: {
          isAuthentication: true,
        },
        props: true,
        component: () => import("@/views/product-categories/edit.vue"),
      },
      {
        path: "contents/posts-search/edit",
        name: "posts-search-edit",
        meta: {
          isAuthentication: true,
        },
        props: (route) => ({
          id: route.query.id,
        }),
        component: () => import("@/views/posts-search/edit.vue"),
      },
      {
        path: "contents/posts-search",
        name: "posts-search",
        component: () => import("@/views/posts-search/index.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          link: route.query.link,
          title: route.query.title,
        }),
        meta: {
          isAuthentication: true,
        },
      },
      {
        path: "projects",
        name: "projects",
        component: () => import("@/views/projects/index.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
        meta: {
          isAuthentication: true,
          permissions: ["projects"],
        },
      },
      {
        path: "projects/add",
        name: "projects-add",
        meta: { role: "create" },
        component: () => import("@/views/projects/add.vue"),
      },
      {
        path: "projects/edit/:id",
        name: "projects-edit",
        meta: {
          isAuthentication: true,
          permissions: ["projects"],
        },
        props: true,
        component: () => import("@/views/projects/edit.vue"),
      },
      {
        path: "projects/project-categories",
        name: "project_categories",
        component: () => import("@/views/project-categories/index.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
        meta: {
          isAuthentication: true,
          permissions: ["projects"],
        },
      },
      {
        path: "projects/project-categories/add",
        name: "project-categories-add",
        meta: {
          isAuthentication: true,
          permissions: ["projects"],
        },

        component: () => import("@/views/project-categories/add.vue"),
      },
      {
        path: "projects/project-categories/edit/:id",
        name: "project-categories-edit",
        meta: {
          isAuthentication: true,
          permissions: ["projects"],
        },

        props: true,
        component: () => import("@/views/project-categories/edit.vue"),
      },
      {
        path: "contents/experiences",
        name: "experiences",
        meta: {
          isAuthentication: true,
          permissions: ["experiences"],
        },
        component: () => import("@/views/experiences/index.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
      },
      {
        path: "contents/experiences/add",
        name: "experiences-add",
        meta: {
          isAuthentication: true,
          permissions: ["experiences"],
        },
        component: () => import("@/views/experiences/add_2.vue"),
      },
      {
        path: "contents/experiences/edit/:id",
        name: "experiences-edit",
        meta: {
          isAuthentication: true,
          permissions: ["experiences"],
        },
        props: true,
        component: () => import("@/views/experiences/edit_2.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "configs/home",
        name: "home_configs",
        meta: {
          isAuthentication: true,
        },
        component: () => import("@/views/configs/home.vue"),
      },
      {
        path: "configs/general",
        name: "general_configs",
        component: () => import("@/views/configs/general.vue"),
        meta: {
          isAuthentication: true,
        },
      },
      {
        path: "configs/agencies",
        name: "configs_agency",
        component: () => import("@/views/configs/agency.vue"),
        meta: {
          isAuthentication: true,
        },
      },
      {
        path: "configs/menu",
        name: "configs_menu",
        component: () => import("@/views/configs/menu.vue"),
        meta: {
          isAuthentication: true,
        },
      },
      {
        path: "configs/position-career",
        name: "configs_position-career",
        component: () => import("@/views/configs/position_career.vue"),
        meta: {
          isAuthentication: true,
        },
      },
      {
        path: "configs/location-career",
        name: "configs_location-career",
        component: () => import("@/views/configs/location_career.vue"),
        meta: {
          isAuthentication: true,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "form-data/valuation",
        name: "data_valuation",
        component: () => import("@/views/form-data/valuation.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
        meta: {
          isAuthentication: true,
        },
      },
      {
        path: "form-data/post-register",
        name: "data_post_register",
        component: () => import("@/views/form-data/post_register.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
        meta: {
          isAuthentication: true,
        },
      },{
        path: "form-data/curriculum-vitae",
        name: "data_curriculum_vitae",
        component: () => import("@/views/form-data/curriculum-vitae.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
        meta: {
          isAuthentication: true,
        },
      },
      {
        path: "form-data/broker-request-upgrade",
        name: "broker_request_upgrade",
        component: () => import("@/views/form-data/broker_request_upgrade.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
        meta: {
          isAuthentication: true,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "agencies",
        name: "agencies",
        component: () => import("@/views/agencies/index.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
      },
      {
        path: "agencies/add",
        name: "agencies-add",

        meta: {
          isAuthentication: true,
          permissions: ["agencies"],
        },
        component: () => import("@/views/agencies/add.vue"),
      },
      {
        path: "agencies/edit/:id",
        name: "agencies-edit",
        meta: {
          isAuthentication: true,
          permissions: ["agencies"],
        },

        props: true,
        component: () => import("@/views/agencies/edit.vue"),
      },
      {
        path: "partners",
        name: "partners",
        component: () => import("@/views/partners/index.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
      },
      {
        path: "partners/add",
        name: "partners-add",

        meta: {
          isAuthentication: true,
          permissions: ["partners"],
        },
        component: () => import("@/views/partners/add.vue"),
      },
      {
        path: "partners/edit/:id",
        name: "partners-edit",
        meta: {
          isAuthentication: true,
          permissions: ["partners"],
        },

        props: true,
        component: () => import("@/views/partners/edit.vue"),
      },
      {
        path: "partners/categories",
        name: "partners_categories",
        meta: {
          isAuthentication: true,
          permissions: ["partners"],
        },
        component: () => import("@/views/partners-categories/index.vue"),
        props: (route) => ({
          limit: route.query.limit,
          page: route.query.page,
          order: route.query.order,
          order_by: route.query.order_by,
          search: route.query.search,
        }),
      },
      {
        path: "partners/categories/add",
        name: "partners-categories-add",
        meta: {
          isAuthentication: true,
          permissions: ["partners"],
        },

        component: () => import("@/views/partners-categories/add.vue"),
      },
      {
        path: "partners/categories/edit/:id",
        name: "partners-categories-edit",
        meta: {
          isAuthentication: true,
          permissions: ["partners"],
        },

        props: true,
        component: () => import("@/views/partners-categories/edit.vue"),
      },
    ],
  },

  {
    path: "/",
    component: () => import("@/views/auth/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("@/views/auth/SignIn.vue"),
        meta: {},
      },
      {
        path: "/sign-up",
        name: "sign-up",
        meta: {},
        component: () => import("@/views/auth/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        meta: {},
        component: () => import("@/views/auth/PasswordReset.vue"),
      },
    ],
  },

  {
    // the 404
    // route, when none of the above matches
    path: "/404",
    props: (route) => ({
      name: route.query.name,
    }),
    name: "404",
    component: () => import("@/views/error/Error404.vue"),
  },

  {
    path: "/500",
    name: "500",
    component: () => import("@/views/error/Error500.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/pages/test.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  if (to.meta.isAuthentication) {
    await store
      .dispatch(Actions.GET_CURRENT_USER)
      .then(() => {
        const currentUser = store.getters["currentUser"];
        //console.log(currentUser.value);
        if (currentUser.role_data == null) router.push({ name: "dont_access" });
        const require_permissions = to.meta.permissions;
        if (!store.getters.isUserAuthenticated) {
          router.push({ name: "dont_access" });
        }
        if (Array.isArray(require_permissions)) {
          if (typeof currentUser.role_data.permissions == "undefined") {
            router.push({ name: "error-403" });
          } else {
            let isAccess = true;
            let isSupperAdministrators = false;
            require_permissions.forEach((item) => {
              // //console.log(item)
              if (
                currentUser.role_data.permissions[item] == "undefined" ||
                currentUser.role_data.permissions[item] == false
              ) {
                isAccess = false;
              }
              if (currentUser.role_data.permissions["administrators"]) {
                isSupperAdministrators = true;
              }
            });
            if (!isSupperAdministrators) {
              if (!isAccess) {
                router.push({ name: "error-403" });
              }
            } else {
              store.commit(Mutations.SET_ADMINISTRATORS);
            }
          }
        }
      })
      .catch(() => {
        router.push({ name: "sign-in" });
      });
  }
  next();
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});
// router.beforeEach(async (to, from, next) => {
//     let isAccess = false;
//     const roleUser = {show: false, create: false, edit: false, delete: false};
//     store.commit(Mutations.RESET_LAYOUT_CONFIG);
//     await store.dispatch("GET_CURRENT_USER").then(() => {
//         const currentUser = store.getters.currentUser;
//         if (to.meta.permission && to.meta.role) {
//             store.dispatch("GET_PERMISSIONS_DATA", "?search=" + JSON.stringify({module: to.meta.permissions}))
//                 .then(() => {
//                     const listDataPermissions = store.getters["listDataPermissions"];
//                     //console.log('listDataPermissions', listDataPermissions)
//                 });
//         }
//     });
//
//     setTimeout(() => {
//         window.scrollTo(0, 0);
//     }, 100);
//     next();
// });
export default router;
