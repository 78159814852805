import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Permission {
  name: string;
  module: string;
  id: string;
  _id: string;
}

export interface PermissionInfo {
  errors: Array<string>;
  permission: Permission;
  listData: Array<Permission>;
  totalPages: number;
  totalDocs: number;
}

@Module
export default class PermissionModule
  extends VuexModule
  implements PermissionInfo
{
  errors = [];
  permission = {} as Permission;
  listData = [];
  totalPages = 0;
  totalDocs = 0;

  get getTotalDocs(): number {
    return this.totalDocs;
  }
  get getTotalPages(): number {
    return this.totalPages;
  }
  get getPermissionErrors(): Array<string> {
    return this.errors;
  }

  get listDataPermissions(): Array<Permission> {
    return this.listData;
  }

  get currentPermission(): Permission {
    this.permission.id = this.permission._id;
    return this.permission;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  ["SET_CURRENT_PERMISSION"](data) {
    this.permission = data;
  }

  @Mutation
  ["M_GET_PERMISSIONS_DATA"](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }

  @Action
  ["GET_PERMISSIONS_DATA"](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("permission", params)
        .then(({ data }) => {
          this.context.commit("M_GET_PERMISSIONS_DATA", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  ["GET_PERMISSIONS_DETAIL"](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("permission/show", id)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_PERMISSION", data.data);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  ["ACTION_ADD_PERMISSION"](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("permission/create", credentials)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_PERMISSION", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  ["UPDATE_PERMISSION"](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("permission/update", payload)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_PERMISSION", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  ["DELETE_PERMISSION"](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("permission/delete/" + payload.id)
        .then(() => {
          this.context.dispatch("GET_PERMISSIONS_DATA", payload.searchString);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
