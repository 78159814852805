import { image } from "@/store/model";

export const default_image: image = {
  path: "/images",
  name: "default-placeholder.jpg",
};
export const size_image_thumbnail = {
  width: "131",
  height: "71",
};
export const size_image_medium = {
  width: "131",
  height: "71",
};

export const statusDataForm =  {
  cancel: "Huỷ",
  pending: "Chờ xử lý",
  success: "Check",
  sale: "Sale",
  trust: "Khách net",
  trash: "Khách rác",
}

interface AppConfigs {
  limit: any;
  size_image_thumbnail: {
    width: string;
    height: string;
  };
  api_url: string;
  media_api_url: string;
}

const Config: AppConfigs = {
  size_image_thumbnail: {
    width: "131",
    height: "71",
  },
  limit: [10, 20, 50, 100],
  api_url: process.env.VUE_APP_API_URL,
  media_api_url: process.env.VUE_APP_MEDIA_URL,
};
export default Config;
