import ApiService from "@/core/services/ApiService";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { image } from "@/store/model";
import store from "@/store";
export interface Media {
  name: string;
  id: string;
  _id: string;
}
const key_current_parent = "non_media_current_parent_folder";
const key_breadcrumbs_media = "non_breadcrumbs_media";
export interface MediaData {
  errors: Array<string>;
  media: Media;
  listData: Array<Media>;
  totalPages: number;
  totalDocs: number;
  showMedia: boolean;
  actionCall: string;
  currentParent: any;
  breadcrumbData: any;
  actionCallback: string;
  isMultiple: boolean;
  selectMultiMedia?: Array<image>;
  selectMedia?: image;
}
@Module
export default class MediaModule extends VuexModule implements MediaData {
  errors = [];
  media = {} as Media;
  breadcrumbData =
    localStorage.getItem(key_breadcrumbs_media) === null
      ? ([] as Media[])
      : JSON.parse(localStorage.getItem(key_breadcrumbs_media) || "{}");
  currentParent =
    localStorage.getItem(key_current_parent) === null
      ? ""
      : localStorage.getItem(key_current_parent);
  listData = [];
  totalPages = 0;
  totalDocs = 0;
  showMedia = false;
  isMultiple = false;
  actionCall = "";
  actionCallback = "";
  selectMultiMedia = <image[]>[];
  selectMedia = <image>{};
  get getTotalMedia(): number {
    return this.totalDocs;
  }
  get getShowMedia(): boolean {
    return this.showMedia;
  }
  get getCurrentParent(): any {
    return this.currentParent;
  }
  get getBreadcrumbData(): any {
    return this.breadcrumbData;
  }
  get getIsMultiple(): boolean {
    return this.isMultiple;
  }
  get getActionCallback(): string {
    return this.actionCallback;
  }
  get getActionCall(): string {
    return this.actionCall;
  }
  get getSelectMedia(): image {
    return this.selectMedia;
  }
  get getSelectMultiMedia(): Array<image> {
    return this.selectMultiMedia;
  }
  get getMediaPages(): number {
    return this.totalPages;
  }
  get getMediaErrors(): Array<string> {
    return this.errors;
  }
  get listDataMedia(): Array<Media> {
    return this.listData;
  }
  get currentMedia(): Media {
    this.media.id = this.media._id;
    return this.media;
  }
  @Mutation
  [Mutations.RESET_ACTION_MEDIA]() {
    this.actionCallback = "";
    this.actionCall = "";
    this.selectMultiMedia = [];
  }
  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }
  @Mutation
  ["SetCurrentParent"](currentParent) {
    this.currentParent = currentParent;
    localStorage.setItem(key_current_parent, currentParent);
  }
  @Mutation
  ["SetBreadcrumbData"](breadcrumbData) {
    this.breadcrumbData = breadcrumbData;
    localStorage.setItem(key_breadcrumbs_media, JSON.stringify(breadcrumbData));
  }
  @Mutation
  [Mutations.SHOW_MEDIA]() {
    this.showMedia = true;
  }
  @Mutation
  [Mutations.CLOSE_MEDIA]() {
    this.showMedia = false;
  }
  @Mutation
  [Mutations.SELECT_SINGLE_MEDIA]() {
    this.isMultiple = false;
  }
  @Mutation
  [Mutations.SELECT_MULTIPLE_MEDIA]() {
    this.isMultiple = true;
  }
  @Mutation
  [Mutations.SET_ACTION_CALLBACK](action) {
    this.actionCallback = action;
  }
  @Mutation
  [Mutations.SET_SELECT_MEDIA](data) {
    this.selectMedia = data;
  }
  @Mutation
  [Mutations.SET_SELECT_MULTI_MEDIA](data) {
    this.selectMultiMedia = data;
  }
  @Mutation
  [Mutations.SET_ACTION_CALL](action) {
    this.actionCall = action;
  }
  @Mutation
  ["SET_CURRENT_MEDIA"](data) {
    this.media = data;
  }
  @Mutation
  ["M_GET_MEDIA_DATA"](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }
  @Action
  [Actions.GET_DATA_MEDIA](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setMediaUrl();
      ApiService.setHeader();
      ApiService.get("manager-file/admin", params)
        .then(({ data }) => {
          ApiService.setMediaUrl();
          this.context.commit("M_GET_MEDIA_DATA", data);
          ApiService.setBaseURL();
          resolve();
        })
        .catch((error) => {
          //console.log(error);
          this.context.commit(Mutations.SET_ERROR, error.data.message);
          ApiService.setBaseURL();
          reject();
        });
      ApiService.setBaseURL();
    });
  }
  @Action
  ["RENAME_MEDIA"](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setMediaUrl();
      ApiService.setHeader();
      ApiService.put("manager-file/rename", params)
        .then(({ data }) => {
          ApiService.setBaseURL();
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          ApiService.setBaseURL();
          reject();
        });
      ApiService.setBaseURL();
    });
  }

  @Action
  ["GET_MEDIA_DETAIL"](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("news/show", id)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_MEDIA", data.data);
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }
  @Action
  ["ACTION_ADD_MEDIA"](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("news/create", credentials)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_MEDIA", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }
  @Action
  ["NEW_FOLDER"](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setMediaUrl();
      ApiService.setHeader();
      ApiService.post("manager-file/create-folder", credentials)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_MEDIA", data.data);
          resolve(data.data);
          ApiService.setBaseURL();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject(response);
          ApiService.setBaseURL();
        });
    });
  }

  @Action
  ["DELETE_MEDIA"](payload) {
    ApiService.setMediaUrl();
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("manager-file/delete/" + payload)
        .then(() => {
          resolve();
          ApiService.setBaseURL();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
          ApiService.setBaseURL();
        });
    });
  }
}
