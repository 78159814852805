import ApiService from "@/core/services/ApiService";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
export interface PartnerCategory {
  name: string;
  username: string;
  email: string;
  id: string;
  _id: string;
}
const model_name = "partner-category";

export interface PartnerCategoryData {
  errors: Array<string>;
  objectData: PartnerCategory;
  listData: Array<PartnerCategory>;
  totalPages: number;
  totalDocs: number;
}

@Module
export default class PartnerCategoryModule
  extends VuexModule
  implements PartnerCategoryData
{
  errors = [];
  objectData = {} as PartnerCategory;
  listData = [];
  totalPages = 0;
  totalDocs = 0;

  get getTotalPartnerCategory(): number {
    return this.totalDocs;
  }
  get getPartnerCategoryPages(): number {
    return this.totalPages;
  }
  get getPartnerCategoryErrors(): Array<string> {
    return this.errors;
  }

  get listDataPartnerCategory(): Array<PartnerCategory> {
    return this.listData;
  }

  get currentPartnerCategory(): PartnerCategory {
    this.objectData.id = this.objectData._id;
    return this.objectData;
  }

  @Mutation
  [Mutations.SET_ERROR_PARTNER_CATEGORY](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_CURRENT_PARTNER_CATEGORY](data) {
    this.objectData = data;
  }

  @Mutation
  [Mutations.SET_ALL_PARTNER_CATEGORIES_DATA](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }

  @Action
  [Actions.GET_ALL_PARTNERS_CATEGORY](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(model_name, params)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ALL_PARTNER_CATEGORIES_DATA, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PARTNER_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [Actions.GET_PARTNERS_CATEGORY_DETAIL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(model_name + "/show", id)
        .then(({ data }) => {
          this.context.commit(
            Mutations.SET_CURRENT_PARTNER_CATEGORY,
            data.data
          );
          resolve();
          return data.data;
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PARTNER_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [Actions.ADD_PARTNER_CATEGORY](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(model_name + "/create", credentials)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_PARTNER_CATEGORY", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PARTNER_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PARTNER_CATEGORY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(model_name + "/update", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_CURRENT_PARTNER_CATEGORY, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PARTNER_CATEGORY,
            response.data.message
          );
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_PARTNER_CATEGORY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(model_name + "/delete/" + payload.id)
        .then(() => {
          this.context.dispatch("GET_CATEGORIES_DATA", payload.searchString);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(
            Mutations.SET_ERROR_PARTNER_CATEGORY,
            response.data.errors
          );
          reject();
        });
    });
  }
}
