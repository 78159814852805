import ApiService from "@/core/services/ApiService";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

const prefix = "ACCOUNT_";

export interface Account {
  name: string;
  username: string;
  email: string;
  id: string;
  _id: string;
}

export interface AccountData {
  errors: Array<string>;
  account: Account;
  listData: Array<Account>;
  totalPages: number;
  totalDocs: number;
}

@Module
export default class AccountModule extends VuexModule implements AccountData {
  errors = [];
  account = {} as Account;
  listData = [];
  totalPages = 0;
  totalDocs = 0;

  get getTotalAccounts(): number {
    return this.totalDocs;
  }

  get getAccountPages(): number {
    return this.totalPages;
  }

  get getAccountErrors(): Array<string> {
    return this.errors;
  }

  get listDataAccounts(): Array<Account> {
    return this.listData;
  }

  get currentAccount(): Account {
    this.account.id = this.account._id;
    return this.account;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  ["SET_CURRENT_ACCOUNT"](data) {
    this.account = data;
  }

  @Mutation
  ["M_GET_ACCOUNTS_DATA"](data) {
    this.listData = data.data;
    this.totalDocs = data.totalDocs;
    this.totalPages = data.totalPages;
  }

  @Action
  ["GET_ACCOUNTS_DATA"](params) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("auth/admin", params)
        .then(({ data }) => {
          this.context.commit("M_GET_ACCOUNTS_DATA", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  ["GET_ACCOUNT_DETAIL"](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get("auth/admin/show", id)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_ACCOUNT", data.data);
          resolve(data.data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject(response.data.message);
        });
    });
  }

  @Action
  ["ACTION_ADD_ACCOUNT"](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post("auth/admin/register", credentials)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_ACCOUNT", data);
          resolve(data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject(response.data.message);
        });
    });
  }

  @Action
  ["UPDATE_ACCOUNT"](payload) {
    ApiService.setHeader();

    return new Promise<void>((resolve, reject) => {
      ApiService.put("auth/admin/update-info-account", payload)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_ACCOUNT", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  ["UPDATE_PROFILE"](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("auth/admin/update-info", payload)
        .then(({ data }) => {
          this.context.commit("SET_CURRENT_ACCOUNT", data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.message);
          reject();
        });
    });
  }

  @Action
  ["DELETE_ACCOUNT"](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("auth/admin/delete?id=" + payload.id + "&destroy=true")
        .then(() => {
          this.context.dispatch("GET_ACCOUNTS_DATA", payload.searchString);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
